import { INavItemNodeData } from '@components/modules/global/navigation/drawerNav'
import { useLocation } from '@reach/router'
import React, { ReactElement, createContext, useEffect, useState } from 'react'

// normalising the data to be used in this context
function nodeToItem(
  node: INavItemNodeData,
  index: number,
  breadcrumbs: string[] = []
): null | BASE.Provider.INavItem {
  if (!!node.hideInNavigation) return null
  return {
    id: node.id,
    label: node.titleNavigation,
    url: node.fields?.fullPath,
    index: index,
    children: node.subPages
      ?.map((child: INavItemNodeData, index) =>
        nodeToItem(child, index, breadcrumbs.concat([node.id]))
      )
      .filter((entry) => !!entry)
      .map((entry, index) => ({ ...entry, index })) as BASE.Provider.INavItem[],
    breadcrumbs: breadcrumbs?.concat([node.id]),
  }
}

const clampNumber = (num: number, a: number, b: number) =>
  Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b))

export interface INavigationContext {
  items: Array<BASE.Provider.INavItem>
  activeItem: BASE.Provider.INavItem | undefined | null
  stateLevel: number
  setActiveItem: (item: BASE.Provider.INavItem | null | undefined) => void
  focusHandler: React.FocusEventHandler<HTMLElement>
  blurHandler: React.FocusEventHandler<HTMLElement>
  keyHandler: React.KeyboardEventHandler<HTMLElement>
  isActiveItem: (id: string) => boolean
  isActiveBreadcrumb: (id: string) => boolean
}

export const NavigationContext = createContext<INavigationContext>({
  items: [],
  activeItem: undefined,
  stateLevel: 1,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  focusHandler: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  blurHandler: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  keyHandler: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveItem: () => {},
  isActiveItem: () => false,
  isActiveBreadcrumb: () => false,
})

export type NavigationProviderProps = {
  pages: Array<INavItemNodeData>
  children: ReactElement
}

const NavigationProvider = ({
  pages,
  children,
}: NavigationProviderProps): ReactElement => {
  const [items] = useState(
    pages
      .map((page: INavItemNodeData, index) => nodeToItem(page, index))
      .filter((item) => !!item)
      .map((entry, index) => ({ ...entry, index })) as BASE.Provider.INavItem[]
  )

  const [activeItem, setStateActiveItem] = useState<
    BASE.Provider.INavItem | null | undefined
  >(null)

  const [keyDirections, setKeyDirections] = useState({ x: 0, y: 0 })

  const location = useLocation()
  const stateLevel = activeItem?.breadcrumbs?.length || 1

  function setActiveItem(item: BASE.Provider.INavItem | null | undefined) {
    setStateActiveItem(item)
  }

  function isActiveItem(id: string): boolean {
    return activeItem?.id === id
  }

  function isActiveBreadcrumb(id: string): boolean {
    return activeItem?.breadcrumbs?.includes(id) || false
  }

  function getItemById(items: BASE.Provider.INavItem[], id: string) {
    const recursiveFind = (
      items: BASE.Provider.INavItem[],

      id: string
    ): BASE.Provider.INavItem | undefined => {
      let foundItem: BASE.Provider.INavItem | undefined = undefined
      items.forEach((item) => {
        if (item.id === id) {
          foundItem = item
        }
        if (!foundItem && item.children) {
          foundItem = recursiveFind(item.children, id)
        }
      })
      return foundItem
    }
    return recursiveFind(items, id)
  }

  function getCurrentLevelItems() {
    if (!activeItem) {
      return items
    }
    return stateLevel > 1
      ? getItemById(
          items,
          activeItem.breadcrumbs[activeItem.breadcrumbs.length - 2]
        )?.children || []
      : items
  }

  function blurHandler(event: React.FocusEvent<HTMLElement>) {
    const target = event.target as HTMLElement

    if (target.classList.contains('.focusActive')) {
      target.classList.remove('.focusActive')
    }

    const id = target.getAttribute('id')
    if (!!id && id === activeItem?.id) {
      setActiveItem(undefined)
    }

    event.stopPropagation()
  }

  function focusHandler(event: React.FocusEvent<HTMLElement>) {
    const target = event.target as HTMLElement
    const id = target.getAttribute('id')
    if (!!id) {
      const itemFromId = getItemById(items, id)
      if (!!itemFromId) {
        target.classList.add('.focusActive')
        setActiveItem(itemFromId)
      }
    }
    event.stopPropagation()
  }

  function handleScroll() {
    if (window.scrollY >= 120) {
      setActiveItem(undefined)
    }
  }

  function keyHandler(event: React.KeyboardEvent<HTMLElement>) {
    if (!activeItem) return
    switch (event.key) {
      case 'Tab':
        const levelItems = getCurrentLevelItems()
        if (event.shiftKey && (activeItem.index > 0 || stateLevel > 1)) {
          setKeyDirections({
            x: stateLevel > 1 ? 0 : -1,
            y: stateLevel > 1 ? -1 : 0,
          })
          event.preventDefault()
        } else if (
          !event.shiftKey &&
          activeItem.index < levelItems.length - 1
        ) {
          setKeyDirections({
            x: stateLevel > 1 ? 0 : 1,
            y: stateLevel > 1 ? 1 : 0,
          })
          event.preventDefault()
        } else if (stateLevel === 1) {
          // Break out of navigation
          setActiveItem(undefined)
        } else {
          // prevent default tabbing If not level 1
          event.preventDefault()
        }
        break
      case 'Escape':
        if (event.target instanceof HTMLLIElement) {
          setActiveItem(undefined)
          event.target.blur()
          event.preventDefault()
        }
        break
      case 'ArrowDown':
        setKeyDirections({ x: 0, y: 1 })
        event.preventDefault()
        break
      case 'ArrowUp':
        setKeyDirections({ x: 0, y: -1 })
        event.preventDefault()
        break
      case 'ArrowRight':
        setKeyDirections({ x: 1, y: 0 })
        event.preventDefault()
        break
      case 'ArrowLeft':
        setKeyDirections({ x: -1, y: 0 })
        event.preventDefault()
        break
      case 'Enter':
        if (activeItem?.url && event.target instanceof HTMLLIElement) {
          const targetPage: HTMLAnchorElement = event.target
            .children[0] as HTMLAnchorElement
          if (!!targetPage) {
            targetPage.click()
            setActiveItem(undefined)
            event.target.blur()
          }
        } else {
          if (!activeItem?.url && activeItem.children) {
            setActiveItem(activeItem.children[0])
          }
        }
        event.preventDefault()
        break
      default:
    }
  }

  useEffect(() => {
    if (keyDirections.x === 0 && keyDirections.y === 0) return
    const activeIndex = activeItem ? activeItem.index : -1
    const currentParent = activeItem
      ? getItemById(
          items,
          activeItem.breadcrumbs[activeItem.breadcrumbs.length - 2]
        )
      : undefined
    const currentLevelItems = currentParent?.children
      ? currentParent?.children
      : items

    const nextIndexDirection =
      stateLevel === 1 ? keyDirections.x : keyDirections.y
    const nextMenuDirection =
      stateLevel === 1 ? keyDirections.y : keyDirections.x
    const nextIndex = clampNumber(
      activeIndex + nextIndexDirection,
      0,
      currentLevelItems.length - 1
    )

    // jump into prev menu
    if (
      activeItem &&
      keyDirections.y === -1 &&
      activeItem.breadcrumbs.length > 1
    ) {
      if (stateLevel !== 1 && activeIndex !== 0) {
        // up in level 1 and level 3
        setActiveItem(
          currentParent?.children &&
            currentParent.children[activeItem.index - 1]
        )
      } else {
        // level 2 to 1
        setActiveItem(currentParent)
      }
      return
    } else {
      if (
        stateLevel === 3 &&
        currentParent?.children &&
        keyDirections.x === -1
      ) {
        // from level 3 to 2
        setActiveItem(currentParent)
        return
      }
    }

    // jump into next level menu
    if (nextMenuDirection === 1 && activeItem && activeItem.children) {
      setActiveItem(activeItem.children[0])
      return
    }

    // set next / prev item in same level
    if (nextIndex !== activeIndex) {
      setActiveItem(currentLevelItems[nextIndex])
      return
    }

    // get all edge cases then clean up and merge things and work out logic
  }, [keyDirections])

  useEffect(() => {
    setActiveItem(undefined)
  }, [location.pathname])

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <NavigationContext.Provider
      value={{
        items,
        activeItem,
        stateLevel,
        focusHandler,
        blurHandler,
        keyHandler,
        setActiveItem,
        isActiveItem,
        isActiveBreadcrumb,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationProvider
