import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from '@reach/router'

import { Box, Theme, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'

import Link from '@components/core/link'
import NavItemLevel2 from './navitemLevel2'
import clsx from 'clsx'
import useNavigation from '@system/hooks/useNavigation'

export interface INavItem {
  item: BASE.Provider.INavItem
  isOpen: boolean | undefined
  onFocus: React.FocusEventHandler<HTMLElement>
  onBlur: React.FocusEventHandler<HTMLElement>
}

export default function NavItemLevel1({
  item,
  isOpen,
  onFocus,
  onBlur,
}: INavItem) {
  const location = useLocation()
  const [isHoveredLvl1, setIsHoveredLvl1] = useState(false)
  const navigationValues = useNavigation()

  const ref = useRef<HTMLLIElement | undefined>()

  const hasThirdLevel = useMemo(
    () => item.children?.some((sp) => !!sp.children),
    []
  )
  const useStyles = makeStyles((theme: Theme) => ({
    level1: {
      display: isOpen ? 'block' : 'none',
      background: theme.palette.common.white,
      listStyle: 'none',
      margin: 0,
      position: 'absolute',
      top: 'calc(100% + 32px)',
      right: '-50%',
      padding: theme.spacing(5, 0, 4, 0),
      backgroundColor: theme.palette.background.main,
      borderRadius: `0px 0px ${hasThirdLevel ? '0px' : '20px'} 20px`,
      boxShadow:
        '0px 10px 10px -15px rgba(0, 0, 0, 0.15), -10px 10px 10px -10px rgba(0, 0, 0, 0.15), 10px 10px 10px -10px rgba(0, 0, 0, 0.15)',
      height: !!item.children && hasThirdLevel ? '412px' : 'auto',
    },
  }))

  const classes = useStyles()

  const isFlyoutOpen =
    !!item.children &&
    (navigationValues.isActiveBreadcrumb(item.id) || isHoveredLvl1)

  function renderSubNavi(): JSX.Element | null {
    if (!isFlyoutOpen) return null

    return (
      <Box
        component="ul"
        onMouseLeave={() => setIsHoveredLvl1(false)}
        className={clsx(classes.level1)}
        // onFocus={onFocus}
      >
        {!!item.url && (
          <NavItemLevel2
            item={{
              id: item.id,
              label: item.label,
              url: item.url,
              index: item.index,
              breadcrumbs: item.breadcrumbs,
            }}
            closeNavWindow={() => {
              setIsHoveredLvl1(false)
            }}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
        {!!hasThirdLevel && (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              zIndex: -1,
              left: '100%',
              top: 0,
              bottom: 0,
              minWidth: '400px',
              backgroundColor: theme.palette.background.light,
              borderRadius: `0px 0px 20px 0px`,
            })}
          />
        )}
        {!!item.children &&
          item.children
            .filter((item) => !item.hideInNavigation)
            .map((item) => {
              return (
                <NavItemLevel2
                  key={item.id}
                  item={item}
                  closeNavWindow={() => {
                    setIsHoveredLvl1(false)
                  }}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
              )
            })}
      </Box>
    )
  }

  useEffect(() => {
    if (navigationValues.isActiveItem(item.id)) {
      setIsHoveredLvl1(true)
      if (ref.current === document.activeElement) return
      setTimeout(() => {
        ref.current?.focus()
      }, 0)
    } else {
      const isHovered = navigationValues.isActiveBreadcrumb(item.id)
      setIsHoveredLvl1(navigationValues.isActiveBreadcrumb(item.id))

      if (!isHovered && ref.current === document.activeElement) {
        setTimeout(() => {
          ref.current?.blur()
        }, 0)
      }
    }
  }, [item, ref, navigationValues.activeItem])

  return (
    <Box
      id={item.id}
      component="li"
      onMouseEnter={() => {
        if (!!item.children) {
          setIsHoveredLvl1(true)
        }
      }}
      onMouseLeave={() => {
        setIsHoveredLvl1(false)
      }}
      tabIndex={0}
      ref={ref}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={navigationValues.keyHandler}
      role="none"
      sx={(theme) => ({
        position: 'relative',
        '&:not(:first-of-type)': {
          marginLeft: theme.spacing(8),
          transition: 'color .2s',
        },
        ...(location.pathname.includes(item.url) && {
          color: theme.palette.tertiary.dark,
          '& .MuiSvgIcon-root': {
            color: theme.palette.tertiary.dark,
          },
        }),
      })}
    >
      <Typography
        component={Link}
        linkColor="black"
        variant="navigation"
        tabIndex={-1}
        to={!!item.children ? undefined : item.url}
        sx={() => ({
          cursor: 'pointer',
          '& + ul': {
            display: isHoveredLvl1 ? 'block' : 'none',
          },
          '&:hover': {
            '&::before': {
              top: '19px',
              left: '0',
              right: '0',
              height: '42px',
              content: '""',
              display: 'block',
              position: 'absolute',
            },
            '& > svg': {
              transform: 'scale(1, -1)',
              transition: 'transform .2s',
            },
          },
          lineHeight: 0,
          '& > svg': {
            transform:
              isHoveredLvl1 && navigationValues.activeItem
                ? 'scale(1, -1)'
                : 'scale(1)',
            transition: 'transform .2s',
          },
        })}
        icon={!!item.children ? { name: 'ChevronDown' } : undefined}
      >
        {item.label}
      </Typography>
      {renderSubNavi()}
    </Box>
  )
}
