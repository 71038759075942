import React from 'react'

import HomeLogo from '@static/img/logos/homelogo.svg'
import ArtCircle from '@static/img/art_circle_1.svg'

import { Grid, Box, SvgIcon, Typography, Container } from '@mui/material'
import { INavItemData } from '../navigation/drawerNav'
import useGlobalText from '@system/hooks/useGlobalText'
import Link from '@components/core/link'

export type FooterProps = BASE.Helpers.IReactDefaultProps & {
  navigationItems?: Array<INavItemData>
}

export default function Footer({ navigationItems }: FooterProps) {
  const { getText } = useGlobalText()
  return (
    <Box
      component="footer"
      sx={(theme) => ({
        overflow: 'hidden',
        backgroundColor: theme.palette.background.main,
        boxShadow: theme.boxShadows.teaser,
        marginTop: theme.spacing(10),
        [theme.breakpoints.up('lg')]: {
          marginTop: theme.spacing(20),
        },
      })}
    >
      <Box sx={{ position: 'relative' }}>
        <Container>
          <SvgIcon
            sx={(theme) => ({
              position: 'relative',
              height: '100%',
              width: '100%',
              maxWidth: '135px',
              py: theme.spacing(10),
              zIndex: 10,
              [theme.breakpoints.up('sm')]: {
                maxWidth: '216px',
              },
              [theme.breakpoints.up('lg')]: {
                py: theme.spacing(12),
              },
            })}
            component={HomeLogo}
            viewBox="0 0 216 49"
          />
        </Container>
        <SvgIcon
          component={ArtCircle}
          viewBox="0 0 552 161"
          sx={(theme) => ({
            position: 'absolute',
            height: '100%',
            width: 'auto',
            top: 0,
            right: '-50%',
            zIndex: 5,
            [theme.breakpoints.up('md')]: {
              right: '-25%',
            },
            [theme.breakpoints.up('lg')]: {
              right: '3%',
            },
          })}
        />
      </Box>
      <Box
        sx={(theme) => ({
          paddingTop: theme.spacing(8),
          paddingBottom: theme.spacing(6),
          backgroundColor: theme.palette.background.red,
          color: theme.palette.text.inverted,
          [theme.breakpoints.up('lg')]: {
            py: theme.spacing(10),
          },
        })}
      >
        <Grid
          component={Container}
          container
          justifyContent="space-between"
          direction={{ xs: 'column-reverse', lg: 'row' }}
        >
          <Grid
            item
            xs="auto"
            component={Typography}
            variant="navigation"
            sx={(theme) => ({
              fontSize: theme.fontSize.get('base'),
              marginTop: theme.spacing(16),
              [theme.breakpoints.up('lg')]: {
                marginTop: 0,
              },
            })}
          >
            {getText('footer.copyright')}
          </Grid>
          <Grid
            item
            xs="auto"
            container
            direction={{ xs: 'column', lg: 'row' }}
          >
            {navigationItems?.map((item) => {
              return (
                <Typography
                  key={item.id}
                  component={Link}
                  variant="footer"
                  linkColor="inverted"
                  to={item.fields.fullPath}
                  sx={(theme) => ({
                    '&:not(:last-of-type)': {
                      marginBottom: theme.spacing(6),
                      [theme.breakpoints.up('lg')]: {
                        marginBottom: 0,
                        marginRight: theme.spacing(10),
                      },
                    },
                  })}
                >
                  {item.titleNavigation}
                </Typography>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
