import React, { ReactElement, useState } from 'react'

import { Box, SwipeableDrawer, Typography } from '@mui/material'
import List from '@mui/material/List'
import useGlobalText from '@system/hooks/useGlobalText'

import theme from '@config/theme'
import NavItem from './drawerNavItem'
import Link from '@components/core/link'
import Button from '@components/core/button'

export interface INavItemNodeData {
  index: number
  id: string
  hideInNavigation?: boolean
  titleNavigation: string
  fields: BASE.Contentful.IFields
  url: string // only needed for getText
  icon?: string
  subPages?: Array<INavItemNodeData>
}

export interface IDrawerNav extends BASE.Helpers.IReactDefaultProps {
  items: Array<INavItemNodeData>
  openState: boolean
  toggleDrawer: (open: boolean) => void
  easyLang?: {
    label: string
    to: string
  }
  handleVideoClick: () => void
}

export default function DrawerNav({
  children,
  items,
  openState,
  toggleDrawer,
  easyLang,
  handleVideoClick,
}: IDrawerNav): ReactElement {
  const [navCount, setNavCount] = useState<number>(items.length)
  const { getText } = useGlobalText()

  function renderNavList(items: INavItemNodeData[], level: number) {
    if (level > 0 && items.length >= navCount) {
      setNavCount(items.length + 1)
    }

    return (
      <List
        component="div"
        disablePadding
        sx={{
          //backgroundColor: theme.palette.background.main,
          ...(level === 0 && {
            lineHeight: 1,
          }),
          ...(level === 1 && {
            flex: 1,
          }),
          ...(level === 2 && {
            flex: 1,
          }),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {items
          .filter((item) => !item.hideInNavigation)
          .map((item) => {
            return (
              <NavItem
                key={item.id}
                item={item}
                onNavigate={() => toggleDrawer(false)}
                navHeight={98 + 64 * navCount}
              >
                {!!item.subPages && renderNavList(item.subPages, level + 1)}
              </NavItem>
            )
          })}
        <Box
          onClick={() => handleVideoClick()}
          sx={{ width: '100%', display: 'contents' }}
        >
          <Button
            sx={(theme) => ({
              ...theme.typography.button,
              color: theme.palette.text.inverted,
              borderRadius: '23px',
              padding: theme.spacing(3, 8),
              transition: '0.3s',
              backgroundImage: `linear-gradient(180deg, ${theme.palette.common.red[500]} 35%, ${theme.palette.common.yellow[500]} 80%)`,
              backgroundSize: '100% 300%',
              backgroundPosition: '0 50%',
              margin: '16px 31px 0 24px',
              [theme.breakpoints.up('lg')]: {
                '&:hover': {
                  backgroundPosition: '0 0%',
                },
              },
            })}
          >
            {getText('button.openDiscussion')}
          </Button>
        </Box>
      </List>
    )
  }

  return (
    <SwipeableDrawer
      data-testid="drawer"
      anchor={'right'}
      open={openState}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      PaperProps={{
        sx: {
          borderBottomLeftRadius: '20px',
          pt: 20,
          height: 'auto',
          width: '80vw',
          //backgroundColor: theme.palette.background.light,
        },
      }}
    >
      {renderNavList(items, 0)}
      <Box
        sx={{
          margin: theme.spacing('auto', 6, 10, 6),
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          //margin: theme.spacing('auto', 6, 12, 6),
          //backgroundColor: theme.palette.background.light,
          margin: 0,
          padding: theme.spacing(6),
          backgroundColor: theme.palette.background.light,
        }}
      >
        {!!easyLang && (
          <Typography
            component={Link}
            linkColor="black"
            variant="navigation"
            to={easyLang.to}
            icon={{
              name: 'EasyLang',
              sx: (theme) => ({
                width: '16px',

                marginBottom: theme.spacing(1),
              }),
            }}
            iconLeft
            onClick={() => toggleDrawer(false)}
            sx={(theme) => ({
              fontSize: theme.fontSize.get('base'),
            })}
          >
            {easyLang.label}
          </Typography>
        )}
      </Box>
    </SwipeableDrawer>
  )
}
