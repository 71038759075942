import { Backdrop, Box } from '@mui/material'
import React, { ReactElement } from 'react'

import IconButton from '@components/core/IconButton'
import Video from '@components/media/video'

export type PopUpVideoProps = {
  media: BASE.Contentful.IAsset
  triggerVideoPlay: boolean
  handleVideoClickToClose: () => void
}

export default function PopUpVideo({
  media,
  triggerVideoPlay,
  handleVideoClickToClose,
}: PopUpVideoProps): ReactElement {
  return (
    <Backdrop
      open={triggerVideoPlay}
      onClick={(ev) => {
        // @ts-expect-error tagName is not evailable on target, but it is
        if (ev.target.tagName !== 'VIDEO') handleVideoClickToClose()
      }}
      sx={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.modal,
      })}
    >
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          maxWidth: theme.maxWidth.lg,
          height: 'auto',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
        })}
      >
        <IconButton
          name="Close"
          sx={(theme) => ({
            position: 'absolute',
            top: theme.spacing(4),
            right: theme.spacing(4),
            zIndex: 5,
            [theme.breakpoints.down('sm')]: {
              right: theme.spacing(6),
              width: '48px',
              height: '48px',
            },
          })}
          onClick={() => (triggerVideoPlay = false)}
        />
        {!!media.localFile && (
          <Video
            src={media.localFile.publicURL}
            srcMobile={media.localFile.publicURL}
            triggerPlay={triggerVideoPlay}
            loop
            controls
            sx={(theme) => ({
              width: '95%',
              height: 'auto',
              borderRadius: '16px 16px 30px',
              [theme.breakpoints.up('md')]: {
                width: '100%',
              },
            })}
          />
        )}
      </Box>
    </Backdrop>
  )
}
