import React, { useState } from 'react'
import { Box, Typography, Theme, useMediaQuery } from '@mui/material'
import Icon from '@components/core/icon'
import Link from '@components/core/link'
import { graphql, useStaticQuery } from 'gatsby'

export type M007BannerProps = {
  scrollDir: string
}

export default function M007Banner({ scrollDir }: M007BannerProps) {
  const [bannerClose, setBannerClose] = useState(false)

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const { bannerData } = useStaticQuery(graphql`
    query {
      bannerData: allContentfulM007Banner(
        filter: { id: { eq: "dbd0a5a1-c411-58ff-9264-4fa5a07ee1d6" } }
      ) {
        nodes {
          bannerTitle
          firstLink {
            fields {
              fullPath
            }
            url
            titleNavigation
          }
          secondLink {
            fields {
              fullPath
            }
            url
            titleNavigation
          }
        }
      }
    }
  `)
  if (bannerData.nodes.length === 0) return null

  const hasSecondLink = !!bannerData.nodes[0]?.secondLink
  return (
    <Box
      sx={(theme: Theme) => ({
        position: 'sticky',
        top: scrollDir === 'up' ? '95px' : '0',
        zIndex: 100,
        height: '88px',
        backgroundColor: 'rgb(255, 204, 0)',
        display: bannerClose ? 'none' : 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 40px',
        animation: scrollDir === 'up' ? 'slideIn 0.5s linear' : 'none',
        [theme.breakpoints.down('lg')]: {
          top: scrollDir === 'up' ? '75px' : '0',
          height: 'fit-content',
          alignItems: 'start',
          padding: '16px',
        },
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          name="SpeechBubble"
          sx={(theme: Theme) => ({
            display: 'block',
            margin: '0 42px',
            fontSize: '57px',
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
          })}
        />
        {!bannerClose && bannerData && (
          <Box
            sx={(theme: Theme) => ({
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('lg')]: {
                display: 'block',
                '& > *': {
                  marginBottom: '8px',
                },
              },
            })}
          >
            <Typography sx={{ marginRight: '5px' }}>
              {bannerData.nodes[0]?.bannerTitle}
            </Typography>
            <Box
              sx={(theme: Theme) => ({
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                  flexDirection: 'column',
                  alignItems: 'start',
                  '& > *': {
                    marginBottom: '8px',
                  },
                },
              })}
            >
              <Typography
                sx={(theme: Theme) => ({
                  fontSize: '16px',
                  display: 'flex',
                  [theme.breakpoints.down('lg')]: {
                    marginRight: '6px',
                  },
                })}
              >
                <div>
                  <Link
                    to={
                      bannerData.nodes[0]?.firstLink?.fields?.fullPath ??
                      `/${bannerData.nodes[0]?.firstLink?.url}/`
                    }
                    sx={{
                      color: 'black',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                  >
                    {bannerData.nodes[0]?.firstLink?.titleNavigation}
                  </Link>
                  <Icon
                    name="ArrowRight"
                    sx={{
                      height: '12px',
                      marginLeft: '6px',
                    }}
                  />
                </div>
              </Typography>
              {isDesktop && hasSecondLink && (
                <Typography sx={{ padding: '0 10px' }}>und</Typography>
              )}
              {hasSecondLink && (
                <Typography
                  sx={(theme: Theme) => ({
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '15px',
                    [theme.breakpoints.down('lg')]: {
                      marginRight: '6px',
                    },
                  })}
                >
                  <div>
                    <Link
                      to={
                        bannerData.nodes[0]?.secondLink?.fields?.fullPath ??
                        `/${bannerData.nodes[0]?.secondLink?.url}/`
                      }
                      sx={{
                        color: 'black',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                    >
                      {bannerData.nodes[0]?.secondLink?.titleNavigation}
                    </Link>
                    <Icon
                      name="ArrowRight"
                      sx={{
                        height: '12px',
                        marginLeft: '6px',
                      }}
                    />
                  </div>
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box onClick={() => setBannerClose(true)} sx={{ cursor: 'pointer' }}>
        <Icon name="Close" sx={{ height: '15px' }} />
      </Box>
    </Box>
  )
}
